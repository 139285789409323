<template>
  <div>
    <section id="main">
      <swiper
          :modules="modules"
          :slides-per-view="1"
          :autoplay="autoplayConfig"
          :pagination="pagination1"
          :navigation="navigation1"
          :breakpoints="breakpoints1">
        <swiper-slide>
          <h1>
            ОТКРЫВАЙ<br class="br-mobile">
            Гастрономи<br class="br-mobile">ческие<br><br class="br-mobile"> шедевры<br class="br-mobile">
            С Григорием<br class="br-mobile"> Мосиным <br> <br class="br-mobile">и Углече Поле
          </h1>
          <img src="@/assets/img/main/main-1.jpg"
               srcset="@/assets/img/main/main-1@2x.jpg 2x"
               class="main-bg" alt="">
          <img src="@/assets/img/main/main-1-mobile.jpg"
               srcset="@/assets/img/main/main-1-mobile@2x.jpg 2x"
               class="main-bg-mobile" alt="">
        </swiper-slide>
        <swiper-slide>
          <video class="c-video" preload="none" poster="@/assets/img/main/main-1.jpg" autoplay muted loop playsinline>
            <source src="@/assets/video/main.mp4" type="video/mp4">
          </video>
        </swiper-slide>
      </swiper>
    </section>

    <RecipesPageList/>

    <section>		
      <div class="how-register">
        <div class="how-register__content">
          <div class="section-title --center">
            Продукты
          </div>
          <div class="products-list">
            <div v-for="item in goods" :key="item.id" class="product-item">
            <div class="product-item__img">
              <img :src="item.image_url"  />
            </div>
            <div class="product-item__btn">
              <span class="button button-green">
              {{ item.title }}
              </span>
            </div>
            </div>
          </div>
          <div class="products-list-more" v-if="hasMoreGoods">
            <button @click="loadMoreGoods" class="button button-white">Больше товаров</button>
          </div>
          <div id="buy" class="anchor-block"></div>
          <div class="section-title --center">
            Где купить?
          </div>
          <div class="shops-list">
            <div class="shops-list-item">
              <a href="https://organicmarket.ru" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-1.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://av.ru/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-2.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://online.globus.ru/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-3.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://www.auchan.ru/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-4.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://tvoydom.ru/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-5.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://www.vprok.ru/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-6.svg" alt="">
            </div>
            <div class="shops-list-item">
              <a href="https://lenta.com/" target="_blank" class="shops-list-item__link"></a>
              <img src="@/assets/img/shop-logo/shop-logo-7.svg" alt="">
            </div>
          </div>
          <div class="mini-section-text --center">
            и в других торговых сетях
          </div>

          <div class="museum">
            <div class="museum__info">
              <img src="@/assets/img/museum/logo.svg" class="museum__logo" alt="">
              <div class="museum__info-title">
                Весной мы
                открыли музей
                СырКультПросвет
                при нашем <br> заводе
                в Угличе
              </div>
              <div class="museum__info-description">
                СырКультПросвет — музей сырной культуры и сыродельный завод. Место, где Вы узнаете о сыре, его истории и производстве.
              </div>
              <div class="museum__info-btn">
                <a href="https://syrkultprosvet.ru/" class="button button-white" target="_blank">В музей</a>
              </div>
            </div>
            <div class="museum__media">
              <swiper
                :modules="modules"
                :slides-per-view="1"
                :space-between="10"
                :pagination="pagination3"
                :navigation="navigation3"
                :breakpoints="breakpoints3">
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-1.jpg" 
                  srcset="@/assets/img/museum/museum-img-1@2x.jpg 2x" alt="">
                </swiper-slide>
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-2.jpg" alt="">
                </swiper-slide>
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-3.jpg" alt="">
                </swiper-slide>
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-4.jpg" alt="">
                </swiper-slide>
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-5.jpg" alt="">
                </swiper-slide>
                <swiper-slide>
                <img src="@/assets/img/museum/museum-img-6.jpg" alt="">
                </swiper-slide>
              </swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import RecipesPageList from '@/views/RecipesPageList.vue'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { ref } from 'vue';
import { fetchGoods, fetchFavoriteRecipes, getHomeRecipes } from '@/api';
import metrikaMixin from '@/mixins/metrikaMixin';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'HomePage',
  mixins: [metrikaMixin],
  components: {
    Swiper,
    SwiperSlide,
    RecipesPageList,
  },
  setup() {

    const buttonRefs = ref({});
    return {
      modules: [ Navigation, Pagination, Autoplay ],
      // Настройки для первого Swiper
      breakpoints1: {
        320: {
          slidesPerView: 1,
        },
      },
      pagination1: { clickable: true },
      navigation1: false,

      // Настройки для второго Swiper
      breakpoints2: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
      pagination2: false,
      navigation2: true,

      // Настройки для третьего Swiper
      breakpoints3: {
        320: {
          slidesPerView: 1,
        }
      },
      pagination3: false,
      navigation3: true,
      buttonRefs,
    };
  },
  data() {
    return {
      autoplayConfig: {
        delay: 7000,
        disableOnInteraction: false
      },
      isNameFieldDisabled: false,
      tabs: ['Гарантированные', 'Специальные'],
      selectedTab: 'Гарантированные',
      prizes: [],
      guaranteedPrizes: [],
      specialPrizes: [],
      goods: [],
      limit: 6,
      offset: 0,
      hasMoreGoods: true,
      allRecipes: [],
      recipes: [],
      rootUrl: window.location.origin,
      userId: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупай:', text: 'Продукцию Углече Поле', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируй', text: 'Чеки на промо-сайте или в чат-боте', component: 'RegisterSection', scrolled: false},
        { name: 'Выигрывай', text: 'Круиз до Углича на двоих или мастер-класс от шеф-повара Григория Мосина и другие ценные призы', component: 'PrizeSection', scrolled: false },
        { name: 'Выкладывай', text: 'Кулинарные шедевры на странице Вконтакте для участия в специальном розыгрыше призов', component: 'ShareSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },

  async created() {
    await this.fetchAllRecipes();
    await this.loadGoods();
    try {
      // Запросы выполняются параллельно
      const [recipesData] = await Promise.all([
        fetchFavoriteRecipes(),
      ]);

      // Присваивание данных
      this.recipes = recipesData.results;

    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async fetchAllRecipes() {
      try {
        const data = await getHomeRecipes();
        this.allRecipes = data.results;
      } catch (error) {
        console.error('Ошибка при загрузке рецептов:', error);
      }
    },


    async loadGoods() {
      try {
        const data = await fetchGoods(this.limit, this.offset);       
        this.goods.push(...data.results);
        this.offset += this.limit;
        this.hasMoreGoods = data.count > this.offset; 
      } catch (error) {
        console.error('Error loading goods:', error);
      }
    },
    async loadMoreGoods() {
      await this.loadGoods();
    },

    goToRecipe(id) {
      this.$router.push({ name: 'Recipe', params: { id } });
      window.scrollTo(0, 0);
    },
    redirectToHomePage() {
      this.$router.push('/');
    },

    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
  },
};
</script>

<style>
/* Ваши стили */
</style>
